import useRouteQueryBase from './_useRouteQueryBase'

const checkFn = (x) => x
const parseFn = x => String(x || '').trim()
const defaultValueFn = () => ''

export default useRouteQueryBase(checkFn, parseFn, defaultValueFn)

// import { useRoute, useRouter } from 'vue-router'
// import { watch, ref } from 'vue'
// import useRouteQuerySet from './_useRouteQuerySet'

// export default (queryName, defaultValue = null) => {
//   const route = useRoute()
//   const router = useRouter()
//   const reactiveValue = ref(route.query[queryName] ? String(route.query[queryName]) : defaultValue)
//   const setRoute = val => useRouteQuerySet(queryName, String(val) || null, router, route)
//   watch(() => reactiveValue.value, setRoute)
//   setRoute(reactiveValue.value)
//   return reactiveValue
// }
